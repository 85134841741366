<template>
  <div>
    <div style="text-align: right; margin-right: 4rem;padding-top: 2rem ;">
      <a class="header-widget-icon" :uk-tooltip="`title: ${$t('home.languages')} ; pos: left ;offset:0`
        ">
        <img class="rounded-sm" :src="languageFlag || getLanguageFlag" alt="" height="36" width="36" />
      </a>
      <!-- Language dropdown -->
      <div ref="languageRefs" class="dropdown-notifications dropdown-languages"
        uk-dropdown=" pos: top-right;mode:click">
        <!-- notification contents -->
        <div class="dropdown-notifications-content" data-simplebar>
          <!-- notiviation list -->
          <ul style="background-color: white;">
            <template v-for="(item, i) in languages">
              <li v-if="item.lang !== activeLanguage" class="navi-item" :class="{
                'navi-item-active': isActiveLanguage(item.lang),
              }" :key="i">
                <a href="#" class="navi-link" v-bind:data-lang="item.lang" v-on:click="selectedLanguage">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="item.flag" alt="" class="h-23px w-23px rounded-sm" />
                  </span>
                  <span class="navi-text">{{ item.name }}</span>
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div uk-height-viewport class="uk-flex uk-flex-middle">
      <div class="uk-width-3-3@m uk-width-1-3@s m-auto rounded">
        <div class="uk-child-width-2-2@m uk-grid-collapse" uk-grid>
          <div class="uk-card-default p-5 rounded">
            <div class="mb-4">
              <h3 class="mb-3">{{ $t("login.create_password_title") }}</h3>
              <p>{{ $t("login.craete_password") }}</p>
            </div>
            <form @submit.prevent="onSubmit()">
              <div class="uk-form-group">
                <label class="uk-form-label"> {{ $t("general.email") }} </label>
                <div class="uk-position-relative w-100">
                  <span class="uk-form-icon">
                    <i class="icon-feather-mail"></i>
                  </span>
                  <input class="uk-input" type="email" v-model="form.email" required />
                </div>
              </div>

              <div class="uk-form-group">
                <label class="uk-form-label">
                  {{ $t("general.password") }}
                </label>
                <div class="uk-position-relative w-100 position-relative">
                  <span class="uk-form-icon">
                    <i class="icon-feather-lock"></i>
                  </span>
                  <input class="uk-input" :type="passwordType ? 'text' : 'password'" v-model="form.password" required />
                  <i class="icon-feather-eye open-password" @click="openPassword('passwordType')"></i>
                </div>
                <ol class="bg-white W-100 pt-1 pl-2 mb-1">
                  <li v-if="passwordValidation.hasUpperLetter" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                      $t('errors.password_must_have_upper_latter') }}
                  </li>
                  <li v-if="passwordValidation.hasLowerLetter" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                      $t('errors.password_must_have_lower_latter') }}
                  </li>
                  <li v-if="passwordValidation.hasNumber" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                      $t('errors.password_must_have_number') }}
                  </li>
                  <li v-if="passwordValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                      $t('errors.password_must_more_than_7') }}
                  </li>
                  <li v-if="!passwordValidation.hasUpperLetter" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
                      $t('errors.password_must_have_upper_latter') }}
                  </li>
                  <li v-if="!passwordValidation.hasLowerLetter" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
                      $t('errors.password_must_have_lower_latter') }}
                  </li>
                  <li v-if="!passwordValidation.hasNumber" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_have_number')
                    }}
                  </li>
                  <li v-if="!passwordValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_more_than_7')
                    }}
                  </li>
                </ol>
              </div>

              <div class="uk-form-group">
                <label class="uk-form-label">
                  {{ $t("general.password_confirm") }}
                </label>
                <div class="uk-position-relative w-100 position-relative">
                  <span class="uk-form-icon">
                    <i class="icon-feather-lock"></i>
                  </span>
                  <input class="uk-input" :type="confirmPasswordType ? 'text' : 'password'"
                    v-model="form.password_confirmation" required />
                  <i class="icon-feather-eye open-password" @click="openPassword('confirmPasswordType')"></i>
                </div>
                <ol class="bg-white W-100 pt-1 pl-2 mb-1">
                  <li v-if="passwordConfirmValidation.hasUpperLetter" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                      $t('errors.password_must_have_upper_latter') }}
                  </li>
                  <li v-if="passwordConfirmValidation.hasLowerLetter" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                      $t('errors.password_must_have_lower_latter') }}
                  </li>
                  <li v-if="passwordConfirmValidation.hasNumber" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                      $t('errors.password_must_have_number') }}
                  </li>
                  <li v-if="passwordConfirmValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
                      $t('errors.password_must_more_than_7') }}
                  </li>
                  <li v-if="!passwordConfirmValidation.hasUpperLetter" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
                      $t('errors.password_must_have_upper_latter') }}
                  </li>
                  <li v-if="!passwordConfirmValidation.hasLowerLetter" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
                      $t('errors.password_must_have_lower_latter') }}
                  </li>
                  <li v-if="!passwordConfirmValidation.hasNumber" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_have_number')
                    }}
                  </li>
                  <li v-if="!passwordConfirmValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_more_than_7')
                    }}
                  </li>
                </ol>
              </div>

              <div class="uk-alert-danger" uk-alert v-if="errors">
                <a class="uk-alert-close" uk-close></a>
                <p style="color: red">
                  <i class="uil-exclamation-triangle"></i>{{ $t(errors) }}
                </p>
              </div>

              <div class="uk-alert-success" uk-alert v-if="message">
                <a class="uk-alert-close" uk-close></a>
                <p style="color: green">
                  <i class="uil-exclamation-triangle"></i> {{ message }}
                </p>
              </div>

              <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
                <div class="uk-width-auto@s">
                  <button type="button" class="btn btn-warning mr-2" @click="$router.push({ name: 'login' })">
                    {{ $t("general.back") }}
                  </button>
                  <button type="submit" class="btn btn-default">
                    {{ $t("forgot_password.send") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { CREATE_PASSWORD, SET_ERROR } from "@/core/services/store/auth.module";
import i18nService from "@/core/services/i18n.service.js";
export default {
  name: "CreatePassword",
  data() {
    return {
      languages: i18nService.languages,
      languageFlag: "",
      form: {
        email: null,
        password: null,
        password_confirmation: null,
        token: null,
        alias: null,
      },
      confirmPasswordType: false,
      passwordType: false,
      message: null,
    };
  },
  computed: {
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    passwordValidation() {
      let validation = {
        'hasLowerLetter': false,
        'hasUpperLetter': false,
        'hasNumber': false,
        'hasAtLeast8Letters': false,
      };
      if (this.form.password) {
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;

        if (this.form.password.match(lowerCaseLetters)) {
          validation.hasLowerLetter = true;
        }
        if (this.form.password.match(upperCaseLetters)) {
          validation.hasUpperLetter = true;
        }
        if (this.form.password.match(numbers)) {
          validation.hasNumber = true;

        }
        if (this.form.password.length >= 8) {
          validation.hasAtLeast8Letters = true;
        }
      }

      return validation
    },
    passwordConfirmValidation() {
      let validation = {
        'hasLowerLetter': false,
        'hasUpperLetter': false,
        'hasNumber': false,
        'hasAtLeast8Letters': false,
      };
      if (this.form.password_confirmation) {
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;

        if (this.form.password_confirmation.match(lowerCaseLetters)) {
          validation.hasLowerLetter = true;
        }
        if (this.form.password_confirmation.match(upperCaseLetters)) {
          validation.hasUpperLetter = true;
        }
        if (this.form.password_confirmation.match(numbers)) {
          validation.hasNumber = true;

        }
        if (this.form.password_confirmation.length > 8) {
          validation.hasAtLeast8Letters = true;
        }
      }

      return validation
    },
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    selectedLanguage(e) {
      if (e) {
        const el = e.target.closest(".navi-link");
        const lang = el.getAttribute("data-lang");
        i18nService.setActiveLanguage(lang);
        // this.$emit(
        //   "language-changed",
        //   this.languages.find((val) => {
        //     return val.lang === lang;
        //   })
        // );
        // = {
        //   "language_id": JSON.parse(this.secureLocalStorage("languages")).find(o => {
        //     if (o.code === lang)
        //       return o
        //   })['id']
        // };
        window.location.reload();
        // axios.post(`api/user/change-language`,
        //   languageInfo, {
        //   headers: this.headers
        // }).then((result) => {
        //   window.location.reload();
        // })
      }
    },
    isActiveLanguage(current) {
      return this.activeLanguage === current;
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    openPassword(element) {
      switch (element) {
        case "passwordType":
          this.passwordType = !this[element];
          break;
        case "confirmPasswordType":
          this.confirmPasswordType = !this[element];
          break;
      }
    },
    onSubmit() {
      let form = this.form;

      form.alias = process.env.VUE_APP_DEFAULT_SUBDOMAIN;

      if (['production', 'staging'].includes(process.env.NODE_ENV)) {
        form.alias = window.location.hostname.split(".")[0];
      }

      if (this.form.password !== this.form.password_confirmation) {
        let errorMessage = this.$t("errors.password_dont_match");
        this.$store.commit(SET_ERROR, errorMessage);
        return false;
      }
      // send forgot password request
      this.$store.dispatch(CREATE_PASSWORD, form).then((response) => {
        this.message = this.$t("messages.reset_password_successfully");
        setTimeout(() => this.$router.push({ name: "login" }), 1000);
      });
    },
  },
  mounted() {
    localStorage.clear();
    console.log('hereeeee');
    this.$store.commit(SET_ERROR, "");
    let attributes = this.$route.fullPath.split("&");
    console.log('hereaaaaa', attributes);
    let token = "";
    attributes.forEach((item) => {
      let attribute = item.split("=");
      if (attribute[0] === "token") {
        token = attribute[1];
      }
    });
    if (token) {
      console.log('here token');
      this.form.token = token;
    }
    // if (!this.form.token) {
    //   this.$router.push({ name: "login" })
    // }
  },
};
</script>

<style scoped>
.open-password {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.open-password:hover {
  cursor: pointer;
}
</style>
